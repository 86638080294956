import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import {
    useGetMovieTicketData,

} from "./services/DataServices";
import { TotalCardGeneral } from "./TotalCards";

import keycloak from "../keycloak";
import { Accordion, AccordionTab } from "primereact/accordion";

function MovieRegistrationPortal() {

    const getMembersData = useGetMovieTicketData();

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    // Calculate total checked-in adults
    const totalCheckedInAdults = getMembersData.data?.result.reduce((acc, item) => {
        return acc + item.checked_in_adults;
    }, 0);

    const totalCheckedInKids = getMembersData.data?.result.reduce((acc, item) => {
        return acc + item.checked_in_kids;
    }, 0);





    const toast = useRef(null);
    const dt = useRef(null);


    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto"
        />
      </span>
            <div className="mt-3 md:mt-0 flex justify-content-end">

                <Button
                    icon="pi pi-upload"
                    className="p-button-help p-button-rounded"
                    onClick={exportCSV}
                    tooltip="Export"
                    tooltipOptions={{ position: "bottom" }}
                />
            </div>
        </div>
    );


    const isUserGroupMember =
        keycloak.authenticated && keycloak.hasRealmRole("User");

    return (
        <div>
            <div className="card">
                {!isUserGroupMember && (
                    <Accordion multiple>
                        <AccordionTab header="General">
                            <div className="grid">
                                {!isUserGroupMember && (
                                    <>
                                        <TotalCardGeneral
                                            name="Total Adults Checkin"
                                            totalAmount={totalCheckedInAdults}
                                        ></TotalCardGeneral>
                                        <TotalCardGeneral
                                            name="Total Kids Checkin"
                                            totalAmount={totalCheckedInKids}
                                        ></TotalCardGeneral>

                                    </>
                                )}
                            </div>
                        </AccordionTab>
                    </Accordion>
                )}
            </div>
            <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
                <Toast ref={toast} />

                <div className="text-3xl text-800 font-bold mb-4">
                    Event Registration Portal
                </div>

                <DataTable
                    ref={dt}
                    value={getMembersData.data?.result}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    paginator={!getMembersData.isLoading}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={globalFilter}
                    header={header}
                    responsiveLayout="scroll"
                >
                    <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                        exportable={false}
                    ></Column>
                    <Column
                        field="id"
                        header="ID"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="barcode"
                        header="Barcode"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="name"
                        header="Name"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="num_tickets_adults"
                        header="Num of adults"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="num_tickets_kids"
                        header="Num of kids"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="seating"
                        header="Seating"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="order_no"
                        header="Order No"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    {/* <Column
                        field="street"
                        header="Street"
                        sortable
                        style={{ minWidth: "16rem" }}
                    ></Column>
                    <Column
                        field="plz"
                        header="Post Code"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="city"
                        header="City"
                        sortable
                        style={{ minWidth: "8rem" }}
                    ></Column>
                    <Column
                        field="country"
                        header="Country"
                        sortable
                        style={{ minWidth: "10rem" }}
                    ></Column> */}
                    <Column
                        field="email"
                        header="Email"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    {/* <Column
                        field="mobile"
                        header="Mobile"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column> */}
                    <Column
                        field="checked_in_adults"
                        header="Adults Check-In"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="checked_in_kids"
                        header="Kids Check-In"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="price"
                        header="Price"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="payment_method"
                        header="Payment Method"
                        sortable
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="payment_status"
                        header="Payment Status"
                        sortable
                        style={{ minWidth: "8rem" }}
                    ></Column>
                </DataTable>

            </div>
        </div>
    );
}

export default MovieRegistrationPortal;
