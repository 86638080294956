import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from 'axios';
import keycloak from "../../keycloak";

const API_URL = 'https://app-im-backend.onrender.com';

// const API_URL = 'http://127.0.0.1:8000';


export const getData = async () => {
  return axios
    .get(`${API_URL}/get`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const useGetData = () => {
  const queryFn = async (): Promise<any> => {
    const tableData = await getData();
    return tableData;
  };
  return useQuery(['get-data'],
    queryFn,
  );

};

export const createNew = async (requestBody: any) => {

  return axios
    .post(`${API_URL}/new`, requestBody, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,

      },
    })
    .then(({ data: newsubs }) => {
      return newsubs
    })
    .catch((error) => {
      throw error
    })
}

export const useCreateNew = () => {
  const queryClient = useQueryClient();
  return useMutation(createNew, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-data']);
    },
  })
}


export const update = async (id: any, requestData: any) => {
  try {
    const response = await axios.put(`${API_URL}/update?id=${id}`, requestData, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<any, any>, Error, { id: any, data: any }>(
    ({ id, data }) => update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-data']);
      },
    }
  );
}

export const deleteEntry = async (id: any) => {
  try {
    const response = await axios.delete(`${API_URL}/delete?id=${id}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const useDeleteEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-data']);
    },
  })
}

export const sendEmail = async (requestBody: any) => {

  return axios
    .post(`${API_URL}/send_email`, requestBody, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,

      },
    })
    .then(({ data: newsubs }) => {
      return newsubs
    })
    .catch((error) => {
      throw error
    })
}

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation(sendEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-members-data']);
    },
  })
}

export const getMembersData = async () => {
  return axios
    .get(`${API_URL}/getmembers`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const useGetMembersData = () => {
  const queryFn = async (): Promise<any> => {
    const tableData = await getMembersData();
    return tableData;
  };
  return useQuery(['get-members-data'],
    queryFn,
  );

};

export const createMember = async (requestBody: any) => {

  return axios
    .post(`${API_URL}/newregister`, requestBody, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,

      },
    })
    .then(({ data: newsubs }) => {
      return newsubs
    })
    .catch((error) => {
      throw error
    })
}

export const useCreateMember = () => {
  const queryClient = useQueryClient();
  return useMutation(createMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-members-data']);
    },
  })
}


export const updateMember = async (id: any, requestData: any) => {
  console.log(id)
  console.log(requestData)
  try {
    const response = await axios.put(`${API_URL}/updatemember?id=${id}`, requestData, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useUpdateMember = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<any, any>, Error, { id: any, data: any }>(
    ({ id, data }) => updateMember(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get-members-data']);
      },
    }
  );
}

export const deleteMember = async (id: any) => {
  try {
    const response = await axios.delete(`${API_URL}/deletemember?id=${id}`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const useDeleteMember = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-members-data']);
    },
  })
}

export const getMovieRegistrationData = async () => {
  return axios
      .get(`${API_URL}/getmoviebookings`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
};

export const useGetMovieTicketData = () => {
  const queryFn = async (): Promise<any> => {
    const tableData = await getMovieRegistrationData();
    return tableData;
  };
  return useQuery(['get-movie-data'],
      queryFn,
  );

};
