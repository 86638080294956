import React, { useState, useRef, useEffect, useMemo } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import moment from "moment";
import { Panel } from "primereact/panel";
import { Avatar } from "primereact/avatar";
import {
  useGetData,
  useCreateNew,
  useUpdate,
  useDeleteEntry,
} from "./services/DataServices";

import keycloak from "../keycloak";

import { TotalCard } from "./TotalCards";
import { Accordion, AccordionTab } from "primereact/accordion";

function SaleEntryPortal() {
  let emptyItem = {
    date: null,
    sale: 0.0,
    bar: 0.0,
    card: 0.0,
    other: 0.0,
    tip: 0.0,
    cal_sale: 0.0,
    sale_diff: 0.0,
    expenses: 0.0,
    barAmount: 0.0,
    out: 0.0,
    description: "",
    platform1_sale: 0.0,
    platform1_bar: 0.0,
    platform1_card: 0.0,
    platform1_other: 0.0,
    platform1_calc_sale: 0.0,
    platform1_sale_diff: 0.0,
    status: 0,
  };

  const getData = useGetData();
  const createNew = useCreateNew();
  const updateEntry = useUpdate();
  const deleteEntry = useDeleteEntry();

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyItem);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const [totalSaleAmount, setTotalSaleAmount] = useState(0);
  const [totalCashAmount, setTotalCashAmount] = useState(0);
  const [totalCardAmount, setTotalCardAmount] = useState(0);
  const [totalOtherAmount, setTotalOtherAmount] = useState(0);
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);
  const [totalOutcastAmount, setTotalOutcastAmount] = useState(0);
  const [totalPlatform1SaleAmount, setTotalPlatform1SaleAmount] = useState(0);
  const [totalPlatform1CashAmount, setTotalPlatform1CashAmount] = useState(0);
  const [totalPlatform1CardAmount, setTotalPlatform1CardAmount] = useState(0);

  const [disabledDates, setDisabledDates] = useState([]); // Months are zero-based (11 represents December)

  const calculateTotal = (data, property) => {
    const total = data?.reduce((total, item) => {
      return total + item[property];
    }, 0);

    return parseFloat(total?.toFixed(2)); // Limit to 2 decimal places
  };

  const calculateAndSetTotals2 = (data, properties) => {
    const newData = data;

    const totals = properties.reduce((acc, property) => {
      const total = calculateTotal(newData, property);
      acc[property] = total;
      return acc;
    }, {});

    // Set state for each total dynamically
    Object.entries(totals).forEach(([property, value]) => {
      switch (property) {
        case "sale":
          setTotalSaleAmount(value);
          break;
        case "bar":
          setTotalCashAmount(value);
          break;
        case "card":
          setTotalCardAmount(value);
          break;
        case "other":
          setTotalOtherAmount(value);
          break;
        case "expenses":
          setTotalExpenseAmount(value);
          break;
        case "out":
          setTotalOutcastAmount(value);
          break;
        case "platform1_sale":
          setTotalPlatform1SaleAmount(value);
          break;
        case "platform1_bar":
          setTotalPlatform1CashAmount(value);
          break;
        case "platform1_card":
          setTotalPlatform1CardAmount(value);
          break;
        // Add cases for other properties as needed
        default:
          break;
      }
    });
  };

  const propertiesToCalculate2 = [
    "sale",
    "bar",
    "card",
    "other",
    "expenses",
    "out",
    "platform1_sale",
    "platform1_bar",
    "platform1_card",
  ];

  const isUserGroupMember =
    keycloak.authenticated && keycloak.hasRealmRole("User");

  const getLastTwoEntriesById = () => {
    let dataToDisplay = getData.data?.result || [];

    // Sorting the data by id in descending order
    dataToDisplay = dataToDisplay.sort((a, b) => b.id - a.id);

    // Extracting the last two entries based on id if user is a group member
    if (isUserGroupMember) {
      dataToDisplay = dataToDisplay.slice(0, 2);
    }

    return dataToDisplay;
  };

  let showData = getLastTwoEntriesById();

  const [dateFilter, setDateFilter] = useState({
    fromDate: null,
    toDate: null,
  });
  const [filteredData, setFilteredData] = useState(showData); // Store filtered data

  const memoizedPropertiesToCalculate = useMemo(() => {
    // Usage
    const propertiesToCalculate = [
      "sale",
      "bar",
      "card",
      "other",
      "expenses",
      "out",
      "platform1_sale",
      "platform1_bar",
      "platform1_card",
    ];

    return propertiesToCalculate;
  }, []);

  useEffect(() => {
    // Define getDisabledDates function here
    // Disable dates new 14.12.2023
    const getDisabledDates = () => {
      const disabledDateStrings = getData.data?.result.map((item) => item.date);
      const disabledDates = disabledDateStrings?.map((dateString) => {
        const [year, month, day] = dateString.split("-").map(Number);
        return new Date(year, month - 1, day); // Months are zero-indexed in JavaScript Dates
      });
      return disabledDates;
    };

    const calculateAndSetTotals = (data, properties) => {
      const newData = data;

      const totals = properties.reduce((acc, property) => {
        const total = calculateTotal(newData, property);
        acc[property] = total;
        return acc;
      }, {});

      // Set state for each total dynamically
      Object.entries(totals).forEach(([property, value]) => {
        switch (property) {
          case "sale":
            setTotalSaleAmount(value);
            break;
          case "bar":
            setTotalCashAmount(value);
            break;
          case "card":
            setTotalCardAmount(value);
            break;
          case "other":
            setTotalOtherAmount(value);
            break;
          case "expenses":
            setTotalExpenseAmount(value);
            break;
          case "out":
            setTotalOutcastAmount(value);
            break;
          case "platform1_sale":
            setTotalPlatform1SaleAmount(value);
            break;
          case "platform1_bar":
            setTotalPlatform1CashAmount(value);
            break;
          case "platform1_card":
            setTotalPlatform1CardAmount(value);
            break;
          // Add cases for other properties as needed
          default:
            break;
        }
      });
    };

    setFilteredData(showData);
    calculateAndSetTotals(showData, memoizedPropertiesToCalculate);
    let datesdisabled = getDisabledDates();
    setDisabledDates(datesdisabled);
  }, [getData.data?.result, showData, memoizedPropertiesToCalculate]);

  const applyDateFilter = () => {
    if (dateFilter.fromDate && dateFilter.toDate) {
      // Filter the DataTable data based on the date range
      const filtered = showData.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= dateFilter.fromDate && itemDate <= dateFilter.toDate;
      });

      // Set the filtered data to display in the DataTable
      setFilteredData(filtered);
      calculateAndSetTotals2(filtered, propertiesToCalculate2);
    } else {
      // If no date filter is applied, display the entire dataset
      setFilteredData(showData);
      calculateAndSetTotals2(showData, propertiesToCalculate2);
    }
  };

  const clearDateFilter = () => {
    // Reset the dateFilter state
    setDateFilter({ fromDate: null, toDate: null });

    // Set filteredData back to the entire dataset
    setFilteredData(showData);

    calculateAndSetTotals2(showData, propertiesToCalculate2);
  };

  const toast = useRef(null);
  const dt = useRef(null);

  const formatCurrency = (value) => {
    return value?.toLocaleString("en-US", {
      style: "currency",
      currency: "EUR",
    });
  };

  const openNew = () => {
    setProduct(emptyItem);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };
  const saveProduct = () => {
    setSubmitted(true);
    if (product.date && product.sale) {
      product.cal_sale = (
        product.bar +
        product.card +
        product.other -
        product.tip
      ).toFixed(2);
      product.sale_diff = (product.cal_sale - product.sale).toFixed(2);

      product.platform1_calc_sale = (
        product.platform1_bar +
        product.platform1_card +
        product.platform1_other
      ).toFixed(2);
      product.platform1_sale_diff = (
        product.platform1_calc_sale - product.platform1_sale
      ).toFixed(2);
      let _product = { ...product };
      if (product.id) {
        if (typeof _product.date !== "string") {
          _product.date = moment(_product.date).format("YYYY-MM-DD");
        }
        updateEntry.mutate(
          {
            id: _product.id,
            data: _product,
          },
          {
            onSuccess: (response) => {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: response.message,
                life: 3000,
              });
            },
            onError: (response) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: response.response.data.detail,
                life: 3000,
              });
            },
          }
        );
      } else {
        _product.idLink = createId();
        if (_product.date) {
          _product.date = moment(_product.date).format("YYYY-MM-DD");
        }
        createNew.mutate(_product, {
          onSuccess: (response) => {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: response.message,
              life: 3000,
            });
          },
          onError: (response) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.response.data.detail,
              life: 3000,
            });
          },
        });
      }
      // setProducts(_products);
      setProductDialog(false);
      setProduct(emptyItem);
    }
  };

  const editProduct = (product) => {
    if (product.status === "Open") {
      product.status = 0;
    }
    if (product.status === "Closed") {
      product.status = 1;
    }
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    // let _products = products.filter(val => val.id !== product.id);
    deleteEntry.mutate(product.id, {
      onSuccess: (response) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: response.message,
          life: 3000,
        });
      },
      onError: (response) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message,
          life: 3000,
        });
      },
    });
    setDeleteProductDialog(false);
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const onDateChange = (e, name) => {
    let _product = { ...product };
    _product[`${name}`] = e.value;
    setProduct(_product);
  };

  const statusDisplay = (rowData) => {
    if (rowData.status === 0) {
      rowData.status = "Open";
    }
    if (rowData.status === 1) {
      rowData.status = "Closed";
    }
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };

  const getSeverity = (rowData) => {
    switch (rowData.status) {
      case "Closed":
        return "success";

      // case 'LOWSTOCK':
      //     return 'warning';

      case "Open":
        return "danger";

      default:
        return null;
    }
  };

  const getTagSeverity = (rowData) => {
    return rowData.sale === rowData.cal_sale ? "success" : "danger";
  };

  //   const actionBodyTemplate = (rowData) => {
  //     return (
  //       <React.Fragment>
  //         <Button
  //           icon="pi pi-pencil"
  //           className="p-button-rounded p-button-success mr-2"
  //           onClick={() => editProduct(rowData)}
  //         />
  //         <Button
  //           icon="pi pi-trash"
  //           className="p-button-rounded p-button-danger"
  //           onClick={() => confirmDeleteProduct(rowData)}
  //         />
  //       </React.Fragment>
  //     );
  //   };

  const actionBodyTemplate = (rowData) => {
    // const isUserGroupMember =
    //   keycloak.authenticated && keycloak.hasRealmRole("User");

    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
          disabled={isUserGroupMember}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteProduct(rowData)}
          disabled={isUserGroupMember}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      <span className="p-input-icon-left w-full md:w-auto">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span>
      <div className="mt-3 md:mt-0 flex justify-content-end">
        <div className="mt-2 text-center">
          <span className="mr-2">Date Range Filter</span>
        </div>
        <Calendar
          value={dateFilter.fromDate}
          onChange={(e) => setDateFilter({ ...dateFilter, fromDate: e.value })}
          showIcon
          className="mr-2"
          placeholder="fromDate"
        />
        <Calendar
          value={dateFilter.toDate}
          onChange={(e) => setDateFilter({ ...dateFilter, toDate: e.value })}
          showIcon
          className="mr-2"
          placeholder="toDate"
        />
        <Button
          icon="pi pi-check"
          className="mr-2 p-button-rounded"
          onClick={applyDateFilter}
          tooltip="Apply"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-times"
          className="p-button-danger mr-2 p-button-rounded"
          onClick={clearDateFilter}
          tooltip="Clear Filter"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>

      <div className="mt-3 md:mt-0 flex justify-content-end">
        <Button
          icon="pi pi-plus"
          className="mr-2 p-button-rounded"
          onClick={openNew}
          tooltip="New"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-danger mr-2 p-button-rounded"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
          tooltip="Delete"
          tooltipOptions={{ position: "bottom" }}
        />
        <Button
          icon="pi pi-upload"
          className="p-button-help p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div>
      <div className="card">
        {!isUserGroupMember && (
          <Accordion multiple>
            <AccordionTab header="General - Calculations">
              <div className="grid">
                {!isUserGroupMember && (
                  <>
                    <TotalCard
                      name="Total Sale"
                      totalAmount={totalSaleAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Bar"
                      totalAmount={totalCashAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Card"
                      totalAmount={totalCardAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Other"
                      totalAmount={totalOtherAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Expenses"
                      totalAmount={totalExpenseAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Out"
                      totalAmount={totalOutcastAmount}
                    ></TotalCard>
                  </>
                )}
              </div>
            </AccordionTab>
            <AccordionTab header="Platform 1 - Calculations">
              <div className="grid">
                {!isUserGroupMember && (
                  <>
                    <TotalCard
                      name="Platform - 1 Sale"
                      totalAmount={totalPlatform1SaleAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Platform - 1 Bar"
                      totalAmount={totalPlatform1CashAmount}
                    ></TotalCard>
                    <TotalCard
                      name="Platform - 1 Card"
                      totalAmount={totalPlatform1CardAmount}
                    ></TotalCard>
                  </>
                )}
              </div>
            </AccordionTab>
          </Accordion>
        )}
      </div>

      <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
        <Toast ref={toast} />

        <DataTable
          ref={dt}
          value={filteredData}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
          responsiveLayout="scroll"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={false}
          ></Column>
          <Column
            field="date"
            header="Date"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="sale"
            header="Sale"
            body={(rowData) => formatCurrency(rowData.sale)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="bar"
            header="Bar"
            body={(rowData) => formatCurrency(rowData.bar)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="card"
            header="Card"
            body={(rowData) => formatCurrency(rowData.card)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="other"
            header="Other"
            body={(rowData) => formatCurrency(rowData.other)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="tip"
            header="Tip"
            body={(rowData) => formatCurrency(rowData.tip)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="cal_sale"
            header="Sale Calc."
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.cal_sale)}
                severity={getTagSeverity(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>

          <Column
            field="sale_diff"
            header="Sale Diff"
            body={(rowData) => formatCurrency(rowData.sale_diff)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="expenses"
            header="Expenses"
            body={(rowData) => formatCurrency(rowData.expenses)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="barAmount"
            header="Bar Amount"
            body={(rowData) => formatCurrency(rowData.barAmount)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="out"
            header="Out"
            body={(rowData) => formatCurrency(rowData.out)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="platform1_sale"
            header="Platform 1 Sale"
            body={(rowData) => formatCurrency(rowData.platform1_sale)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="platform1_bar"
            header="Platform 1 Bar"
            body={(rowData) => formatCurrency(rowData.platform1_bar)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="platform1_card"
            header="Platform 1 Card"
            body={(rowData) => formatCurrency(rowData.platform1_card)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="platform1_other"
            header="Platform 1 Other"
            body={(rowData) => formatCurrency(rowData.platform1_other)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="platform1_calc_sale"
            header="Platform 1 Calc Sale"
            body={(rowData) => (
              <Tag
                value={formatCurrency(rowData.platform1_calc_sale)}
                severity={getTagSeverity(rowData)}
              />
            )}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="platform1_sale_diff"
            header="Platform 1 Sale Diff"
            body={(rowData) => formatCurrency(rowData.platform1_sale_diff)}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusDisplay}
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>

        <Dialog
          visible={productDialog}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "40vw" }}
          header="ZAbschalg Details"
          modal
          className="p-fluid"
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          {product.image && (
            <img
              src={`demo/images/product/${product.image}`}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={product.image}
              className="block mt-0 mx-auto mb-5 w-20rem shadow-2"
            />
          )}
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-6">
                <label htmlFor="date">Date</label>
                <Calendar
                  inputId="date"
                  value={product.date}
                  onChange={(e) => onDateChange(e, "date")}
                  showIcon
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.date,
                  })}
                  disabledDates={disabledDates}
                />
                {submitted && !product.date && (
                  <small className="p-error">Date is required.</small>
                )}
              </div>
              <div className="field col-6">
                <label htmlFor="sale">Sale</label>
                <InputNumber
                  id="sale"
                  value={product.sale}
                  onValueChange={(e) => onInputNumberChange(e, "sale")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.sale,
                  })}
                />
                {submitted && !product.sale && (
                  <small className="p-error">Sale is required.</small>
                )}
              </div>
            </div>
          </div>

          <div className="field">
            <div className="formgrid grid">
              <div className="field col-3">
                <label htmlFor="card">Card</label>
                <InputNumber
                  id="card"
                  value={product.card}
                  onValueChange={(e) => onInputNumberChange(e, "card")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="bar">Bar</label>
                <InputNumber
                  id="bar"
                  value={product.bar}
                  onValueChange={(e) => onInputNumberChange(e, "bar")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="other">Other</label>
                <InputNumber
                  id="other"
                  value={product.other}
                  onValueChange={(e) => onInputNumberChange(e, "other")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
              <div className="field col-3">
                <label htmlFor="tip">Tip</label>
                <InputNumber
                  id="tip"
                  value={
                    (product.tip =
                      (product.card + product.bar + product.other).toFixed(2) -
                      product.sale.toFixed(2))
                  }
                  onValueChange={(e) => onInputNumberChange(e, "tip")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                />
              </div>
            </div>
          </div>
          <div className="field">
            <div className="formgrid grid">
              <div className="field col-4">
                <label htmlFor="sale_calc">Sale Calc</label>
                <InputNumber
                  id="sale_calc"
                  value={(
                    product.bar +
                    product.card +
                    product.other -
                    product.tip
                  ).toFixed(2)}
                  onValueChange={(e) => onInputNumberChange(e, "cal_sale")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                  readOnly
                />
              </div>
              <div className="field col-2">
                {(
                  product.bar +
                  product.card +
                  product.other -
                  product.tip
                ).toFixed(2) === product.sale.toFixed(2) ? (
                  <Avatar
                    icon="pi pi-check"
                    className="mr-2 mt-4"
                    size="medium"
                    style={{ backgroundColor: "#23C552", color: "#ffffff" }}
                    shape="circle"
                  />
                ) : (
                  <Avatar
                    icon="pi pi-times"
                    className="mr-2 mt-4"
                    size="medium"
                    style={{ backgroundColor: "#F84F31", color: "#ffffff" }}
                    shape="circle"
                  />
                )}
              </div>
              <div className="field col-4">
                <label htmlFor="sale_diff">Sale Diff</label>
                <InputNumber
                  id="sale_diff"
                  value={
                    (
                      product.bar +
                      product.card +
                      product.other -
                      product.tip
                    ).toFixed(2) - product.sale.toFixed(2)
                  }
                  onValueChange={(e) => onInputNumberChange(e, "sale_diff")}
                  mode="currency"
                  currency="EUR"
                  locale="en-US"
                  readOnly
                />
              </div>
              <div className="field col-2">
                {(
                  product.bar +
                  product.card +
                  product.other -
                  product.tip
                ).toFixed(2) -
                  product.sale.toFixed(2) ===
                0 ? (
                  <Avatar
                    icon="pi pi-check"
                    className="mr-2 mt-4"
                    size="medium"
                    style={{ backgroundColor: "#23C552", color: "#ffffff" }}
                    shape="circle"
                  />
                ) : (
                  <Avatar
                    icon="pi pi-times"
                    className="mr-2 mt-4"
                    size="medium"
                    style={{ backgroundColor: "#F84F31", color: "#ffffff" }}
                    shape="circle"
                  />
                )}
              </div>
            </div>
          </div>
          <Panel header="Expenses Section">
            <div className="field">
              <div className="formgrid grid">
                <div className="field col-4">
                  <label htmlFor="expenses">Expenses</label>
                  <InputNumber
                    id="expenses"
                    value={product.expenses}
                    onValueChange={(e) => onInputNumberChange(e, "expenses")}
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
                <div className="field col-4">
                  <label htmlFor="barAmount">Bar Amount</label>
                  <InputNumber
                    id="barAmount"
                    value={product.barAmount}
                    onValueChange={(e) => onInputNumberChange(e, "barAmount")}
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
                <div className="field col-4">
                  <label htmlFor="out">Out</label>
                  <InputNumber
                    id="out"
                    value={
                      (product.out =
                        product.bar - product.tip - product.expenses)
                    }
                    onValueChange={(e) => onInputNumberChange(e, "out")}
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
              </div>
            </div>
          </Panel>
          <div className="field">
            <label htmlFor="description">Description</label>
            <InputTextarea
              id="description"
              value={product.description}
              onChange={(e) => onInputChange(e, "description")}
              required
              rows={3}
              cols={20}
            />
          </div>
          <Panel header="Lieferando Section">
            <div className="field">
              <div className="formgrid grid">
                <div className="field col-4">
                  <label htmlFor="platform1_sale">Platform 1 Sale</label>
                  <InputNumber
                    id="platform1_sale"
                    value={product.platform1_sale}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "platform1_sale")
                    }
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <div className="formgrid grid">
                <div className="field col-4">
                  <label htmlFor="platform1_bar">Platform 1 Bar</label>
                  <InputNumber
                    id="platform1_bar"
                    value={product.platform1_bar}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "platform1_bar")
                    }
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
                <div className="field col-4">
                  <label htmlFor="platform1_card">Platform 1 Card</label>
                  <InputNumber
                    id="platform1_card"
                    value={product.platform1_card}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "platform1_card")
                    }
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
                <div className="field col-4">
                  <label htmlFor="platform1_other">Platform 1 Other</label>
                  <InputNumber
                    id="platform1_other"
                    value={product.platform1_other}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "platform1_other")
                    }
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="formgrid grid">
                <div className="field col-4">
                  <label htmlFor="platform1_calc_sale">
                    Platform 1 Calc. Sale
                  </label>
                  <InputNumber
                    id="platform1_calc_sale"
                    value={
                      product.platform1_bar +
                      product.platform1_card +
                      product.platform1_other
                    }
                    onValueChange={(e) =>
                      onInputNumberChange(e, "platform1_calc_sale")
                    }
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                    readOnly
                  />
                </div>
                <div className="field col-2">
                  {(
                    product.platform1_bar +
                    product.platform1_card +
                    product.platform1_other
                  ).toFixed(2) === product.platform1_sale.toFixed(2) ? (
                    <Avatar
                      icon="pi pi-check"
                      className="mr-2 mt-4"
                      size="medium"
                      style={{ backgroundColor: "#23C552", color: "#ffffff" }}
                      shape="circle"
                    />
                  ) : (
                    <Avatar
                      icon="pi pi-times"
                      className="mr-2 mt-4"
                      size="medium"
                      style={{ backgroundColor: "#F84F31", color: "#ffffff" }}
                      shape="circle"
                    />
                  )}
                </div>
                <div className="field col-4">
                  <label htmlFor="platform1_sale_diff">
                    Platform 1 Sale Diff
                  </label>
                  <InputNumber
                    id="platform1_sale_diff"
                    value={
                      (
                        product.platform1_bar +
                        product.platform1_card +
                        product.platform1_other
                      ).toFixed(2) - product.platform1_sale.toFixed(2)
                    }
                    onValueChange={(e) =>
                      onInputNumberChange(e, "platform1_sale_diff")
                    }
                    mode="currency"
                    currency="EUR"
                    locale="en-US"
                    readOnly
                  />
                </div>
                <div className="field col-2">
                  {(
                    product.platform1_bar +
                    product.platform1_card +
                    product.platform1_other
                  ).toFixed(2) -
                    product.platform1_sale.toFixed(2) ===
                  0.0 ? (
                    <Avatar
                      icon="pi pi-check"
                      className="mr-2 mt-4"
                      size="medium"
                      style={{ backgroundColor: "#23C552", color: "#ffffff" }}
                      shape="circle"
                    />
                  ) : (
                    <Avatar
                      icon="pi pi-times"
                      className="mr-2 mt-4"
                      size="medium"
                      style={{ backgroundColor: "#F84F31", color: "#ffffff" }}
                      shape="circle"
                    />
                  )}
                </div>
              </div>
            </div>
          </Panel>
        </Dialog>

        <Dialog
          visible={deleteProductDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>
                Are you sure you want to delete entry on <b>{product.date}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {product && (
              <span>Are you sure you want to delete the selected Entries?</span>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default SaleEntryPortal;
