import { useState } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { TabMenu } from "primereact/tabmenu";
import { MenuItem } from "primereact/menuitem";
import MovieRegistrationPortal from "./MoviePortal"
// import StockManagementPortal from "./stockmanagementportal";
import EventRegistrationPortal from "./EventRegistration";
import SaleEntryPortal from "./SaleEntry";
import Home from "./Home";
import keycloak from "../keycloak";
import { Navigate } from "react-router-dom";

function NavBar() {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const handleLogout = () => {
    keycloak.logout(); // Call logout method
    <Navigate to="/" />;
  };

  const items: MenuItem[] = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: () => setActiveTabIndex(0),
    },
    {
      label: "Sale Entry",
      icon: "pi pi-fw pi-calendar",
      command: () => setActiveTabIndex(1),
    },
    {
      label: "Event Registration",
      icon: "pi pi-fw pi-calendar",
      command: () => setActiveTabIndex(2),
    },
    {
      label: "Movie Bookings",
      icon: "pi pi-fw pi-calendar",
      command: () => setActiveTabIndex(3),
    },
    { label: "Logout", icon: "pi pi-fw pi-cog", command: () => handleLogout() },
  ];

  const renderComponentByTab = () => {
    switch (activeTabIndex) {
      case 0:
        return <Home />;
      case 1:
        return <SaleEntryPortal />;
      case 2:
        return <EventRegistrationPortal />;
      case 3:
        return <MovieRegistrationPortal />;
      default:
        return null;
    }
  };

  return (
    <div className="card">
      <TabMenu
        model={items}
        activeIndex={activeTabIndex}
        onTabChange={(e) => setActiveTabIndex(e.index)}
      />
      {renderComponentByTab()}
    </div>
  );
}

export default NavBar;
