import Keycloak, { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
    url: "https://auth.my-server.online/auth",
    realm: "myrealm",
    clientId: "myclient",
  };
  
  const keycloak = new Keycloak(keycloakConfig);
  export default keycloak;
