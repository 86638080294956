import { useCallback } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useLocation } from "react-router-dom";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import Logo from "../assests/logo.png";

export function LoginPage() {
  const location = useLocation();
  const { keycloak } = useKeycloak();

  // Perform type-checking using 'as' assertion
  // const locationState = location.state as { state?: string | null };
  const from =
    location &&
      location.state &&
      Object.values<any>(location.state)[0]?.state !== null
      ? Object.values<any>(location.state)[0]?.state
      : "/";

  const login = useCallback(() => {
    keycloak?.login({
      redirectUri: `${window.location.origin}${"/portal"}${from}`,
    });
  }, [keycloak, from]);

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
        <div className="text-center mb-5">
          <img
            src={Logo}
            alt="racinghub"
            width={500}
            height={300}
            className="mb-3"
          />
          <div className="text-900 text-3xl font-medium mb-3">
            Welcome to Admin Portal
          </div>
          <div className="text-900 text-2xl font-medium mb-3">Sri Vayuputra UG</div>
        </div>

        <div>
          {!keycloak.authenticated && (
            <Button
              label="Login"
              icon="pi pi-user"
              className="w-full"
              onClick={login}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
