import React from "react";

const TotalCardBase = ({ name, totalAmount, includeSymbol = true }) => {
  return (
    <div className="col-12 md:col-6 lg:col-2">
      <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
        <div className="flex justify-content-between mb-3">
          <div>
            <span className="block text-500 font-medium mb-3">{name}</span>
            <div className="text-900 font-medium text-xl">
              {includeSymbol ? `€${totalAmount}` : totalAmount}
            </div>
          </div>
          <div
            className="flex align-items-center justify-content-center bg-blue-100 border-round"
            style={{ width: "2.5rem", height: "2.5rem" }}
          >
            <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TotalCard = ({ name, totalAmount }) => {
  return <TotalCardBase name={name} totalAmount={totalAmount} />;
};

export const TotalCardGeneral = ({ name, totalAmount }) => {
  return (
    <TotalCardBase
      name={name}
      totalAmount={totalAmount}
      includeSymbol={false}
    />
  );
};
